import offset from '../.internal/style/offset';
import select from '../select/select';

/*
 * Return the top and left position of an element relative to the document.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 *
 * @returns {Object}
 */
export default function (node) {

    // Select matching node
    node = select(node);

    // Node Exists
    return node ? offset(node) : null;
}
