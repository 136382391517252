import select from '../select/select';
import each from '../../helpers/collection/each';

/*
 * Append Nodes to a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {Array} nodesChildren
 */
export default function (node, nodesChildren) {

    // Select matching node
    node = select(node);

    // DOM node exists, Append Children
    if (node) {
        each(nodesChildren, nodeChild => {
            node.appendChild(nodeChild);
        });
    }
}
