/*
 * Reef
 *
 * @author Funky Fizz
 * @copyright 2021-present Petra Baggia
 */

// App variables
import Reef from '@reef/js/Reef';

// Plugins - Components

// Plugins - Reef
import '@reef/js/plugins/carousel';
import '@reef/js/plugins/carouselNavigation';
import '@reef/js/plugins/gallery';
import '@reef/js/plugins/imageReveal';
import '@reef/js/plugins/imageSmooth';
import '@reef/js/plugins/inputFloat';
import '@reef/js/plugins/formidable';
import '@reef/js/plugins/popin';
import '@reef/js/plugins/scrollTo';
import '@reef/js/plugins/scrollVibe';
import '@reef/js/plugins/textReveal';
import '@reef/js/plugins/touchable';

// Polyfills
import '@reef/js/polyfills/imagesResponsive';
import '@reef/js/polyfills/lazyload';

// Functions
import domReady from '@reef/js/fn/events/domReady';
import each from '@reef/js/helpers/collection/each';
import keys from '@reef/js/helpers/object/keys';

// Config
const config = {};

// Defaults
const defaults = {
    plugins: {}
};

// DOM Ready
domReady(() => {

    // Start Reef plugins
    each(keys(Reef.plugins), key => {
        Reef.plugins[key](defaults.plugins[key] || {});
    });
});
