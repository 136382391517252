import select from './select';
import selectByTagName from './selectByTagName';

/*
 * Select form inputs
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} node
 *
 * @returns {NodeList|null}
 */
export default function (node) {

    // Select matching node
    node = select(node);

    // DOM node exists, return form inputs
    return node ? [...selectByTagName('input', node), ...selectByTagName('select', node), ...selectByTagName('textarea', node)] : null;
}
