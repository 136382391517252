import select from './select';

/*
 * Select matching parent node from the DOM and return it.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|NodeList|string} node
 * @param {string} selector
 *
 * @returns {boolean}
 */
export default function (node, selector) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.matchesSelector
            || Element.prototype.mozMatchesSelector
            || Element.prototype.msMatchesSelector
            || Element.prototype.oMatchesSelector
            || Element.prototype.webkitMatchesSelector
            || function (s) {
                const matches = (this.document || this.ownerDocument).querySelectorAll(s);
                let i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Select matching node
    node = select(node);

    // Matches
    if (node) {
        return node.matches(selector);
    }

    return false;
}
