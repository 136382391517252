import '../.defaults/show';
import animate from './animate';
import onCallback from '../.internal/callback/onCallback';
import getAttr from '../../fn/attributes/getAttr';
import selectAll from '../../fn/select/selectAll';
import each from '../../helpers/collection/each';
import isNull from '../../helpers/lang/isNull';
import merge from '../../helpers/object/merge';
import Reef from '../../Reef';

/*
 * Show nodes based on the specified transition. Similar to the jQuery animate
 * functions. Possible transitions are: slideIn, slideOut, fadeIn, fadeOut, show,
 * hide.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string|Array} nodes
 * @param {string} transition
 * @param {Object} options
 *
 * @returns {Promise}
 */
export default function (nodes, transition, options) {
    const that = this;
    return new Promise(resolve => {

        // Options
        options = merge(Reef.methods.defaults.show, options);

        // Reveal is Complete
        const complete = () => {
            resolve();
            onCallback.call(that, 'onComplete', options);
        };

        // Nodes do not exist
        if (isNull(nodes)) {
            complete();
        }

        // Create promises
        const promises = [];

        // Add chosen nodes to Promises
        each(selectAll(nodes), node => {

            // Check transition attribute for node
            transition = getAttr(node, 'data-show:transition') || transition;

            // Slide
            if (transition === 'slide' && options.speed !== 0) {
                promises.push(animate(node, 'slideDown', {
                    speed: options.speed,
                    ease: options.ease
                }));

            // Fade
            } else if (transition === 'fade' && options.speed !== 0) {
                promises.push(animate(node, 'fadeIn', {
                    speed: options.speed,
                    ease: options.ease
                }));

            // Show
            } else {
                promises.push(animate(node, 'show'));
            }
        });

        // Once all Promises are resolved
        Promise.all(promises).then(() => {
            complete();
        });
    });
}
