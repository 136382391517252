import attrOption from './.internal/options/attrOption';
import getAttrOption from './.internal/options/getAttrOption';
import Carousel from './Carousel';
import CarouselNavigation from './CarouselNavigation';
import EventsManager from './EventsManager';
import Tweener from './Tweener';
import WheelEvents from './WheelEvents';
import addClass from '../fn/attributes/addClass';
import hasClass from '../fn/attributes/hasClass';
import removeClass from '../fn/attributes/removeClass';
import removeClasses from '../fn/attributes/removeClasses';
import hide from '../fn/attributes/hide';
import show from '../fn/attributes/show';
import scrollLock from '../fn/browser/scrollLock';
import scrollPosition from '../fn/browser/scrollPosition';
import appendChild from '../fn/manipulation/appendChild';
import create from '../fn/manipulation/create';
import clone from '../fn/manipulation/clone';
import prependChild from '../fn/manipulation/prependChild';
import removeChildren from '../fn/manipulation/removeChildren';
import select from '../fn/select/select';
import selectAll from '../fn/select/selectAll';
import offset from '../fn/style/offset';
import imgSize from '../fn/style/imgSize';
import each from '../helpers/collection/each';
import parseBool from '../helpers/lang/parseBool';
import setUndefined from '../helpers/object/setUndefined';
import random from '../helpers/string/random';
import className from '../helpers/utils/className';
import imageLoaded from '../methods/utils/imageLoaded';
import sleep from '../methods/utils/sleep';

/*
 * Gallery enables to see fullscreen images with ease
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const Gallery = function (el, options) {
    const that = this;

    options = options || {};

    // Options
    setUndefined(options, {
        selector: Gallery.SELECTOR,

        // Options
        mode: Gallery.MODE,
        lazy: Gallery.LAZY,
        preload: Gallery.PRELOAD,
        effect: Gallery.EFFECT,
        speedZoom: Gallery.SPEED_ZOOM,
        delayShow: Gallery.DELAY_SHOW,
        delayHide: Gallery.DELAY_HIDE,
        wheel: Gallery.WHEEL,
        id: random(10),
        mirror: ''
    });

    // Set Options from Attr
    options = attrOption(options, el, ['id', 'mode', 'lazy', 'preload', 'mirror', 'effect', 'speedZoom', 'delayShow', 'delayHide', 'wheel'], options.selector);

    // Boolean values
    options.lazy = parseBool(options.lazy);
    options.preload = parseBool(options.preload);
    options.wheel = parseBool(options.wheel);

    // Selectors
    const selectors = {

        // Lazy
        vLazy: '.js-lazy',
        vLazyPreload: '.js-lazy--preload',

        // Statuses
        vLoaded: '.v--loaded',
        vSelected: '.v--selected',
        vFill: '.v--fill',
        vActive: '.v--active',

        // Elements
        carousel: `${options.selector}__carousel`,
        carouselSlide: `${options.selector}__carousel-slide`,
        trigger: `${options.selector}__trigger`,
        close: `${options.selector}__close`,
        ghost: `${options.selector}__ghost`,
        ghostPlaceholder: `${options.selector}__ghost-placeholder`,
        overlay: `${options.selector}__overlay`,

        navigation: `${options.selector}__navigation`,
        navigationPrevious: `${options.selector}__navigation-previous`,
        navigationNext: `${options.selector}__navigation-next`,
        navigationNumerationCurrent: `${options.selector}__navigation-numeration-current`,
        navigationNumerationTotal: `${options.selector}__navigation-numeration-total`
    };

    // Element & Options
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
Gallery.prototype.init = function () {
    const that = this;

    // Elements
    const elTriggers = that.getTriggers();
    const elCarousel = select(that.selectors.carousel, that.el);
    const elCarouselSlides = selectAll(that.selectors.carouselSlide, elCarousel);
    const elClose = select(that.selectors.close, that.el);

    const elNavigation = select(that.selectors.navigation, that.el);
    const elNavigationPrevious = select(that.selectors.navigationPrevious, elNavigation);
    const elNavigationNext = select(that.selectors.navigationNext, elNavigation);
    const elNavigationNumerationCurrent = select(that.selectors.navigationNumerationCurrent, elNavigation);
    const elNavigationNumerationTotal = select(that.selectors.navigationNumerationTotal, elNavigation);

    // Classes
    const classLoaded = className(that.selectors.vLoaded);
    const classOverlay = className(that.selectors.overlay);
    const classGhost = className(that.selectors.ghost);
    const classGhostPlaceholder = className(that.selectors.ghostPlaceholder);

    // Init Carousel
    that.carousel = new Carousel(elCarousel);

    // Variables
    that.slideSelected = elCarouselSlides[0];
    that.animating = false;
    that.open = false;

    // Create additional divs
    prependChild(that.el, create('div', { classNames: classGhost }));
    prependChild(that.el, create('div', { classNames: classOverlay }));
    prependChild(that.el, create('div', { classNames: classGhostPlaceholder }));

    // Enable Navigation
    if (elNavigation) {
        if (elNavigationPrevious) {
            addClass(elNavigationPrevious, className(`${CarouselNavigation.SELECTOR}__previous`));
        }
        if (elNavigationNext) {
            addClass(elNavigationNext, className(`${CarouselNavigation.SELECTOR}__next`));
        }
        if (elNavigationNumerationCurrent) {
            addClass(elNavigationNumerationCurrent, className(`${CarouselNavigation.SELECTOR}__numeration-current`));
        }
        if (elNavigationNumerationTotal) {
            addClass(elNavigationNumerationTotal, className(`${CarouselNavigation.SELECTOR}__numeration-total`));
        }
        new CarouselNavigation(elNavigation, { rel: elCarousel });
    }

    // Listener - Show / Handle
    that.events.on(that.el, 'handleShow', e => {
        if (!that.open && !that.animating) {
            that.show(e.detail.ref || false);
        }

    });

    // Listener - Hide / Handle
    that.events.on(that.el, 'handleHide', () => {
        if (that.open && !that.animating) {
            that.hide();
        }
    });

    // Listener - Trigger /  Click
    each(elTriggers, elTrigger => {
        that.events.on(elTrigger, 'click', e => {
            e.preventDefault();
            that.events.trigger(that.el, 'handleShow', { ref: that.getRef(elTrigger) });
        });
    });

    // Listener - Close / Click
    that.events.on(elClose, 'click', e => {
        e.preventDefault();
        that.events.trigger(that.el, 'handleHide');
    });

    // Listener - Image Loaded
    each(elCarouselSlides, elCarouselSlide => {
        imageLoaded(elCarouselSlide).then(() => {
            addClass(elCarouselSlide, classLoaded);
        });
    });

    // Listeners - Mouse Wheel
    if (that.options.wheel) {
        that.listenMouseWheel();
    }

    // Preload images
    if (that.options.preload) {
        that.preload();
    }
};

/**
 * Get Triggers
 */
Gallery.prototype.getTriggers = function () {
    const that = this;

    const elTriggers = [];
    each(selectAll(that.selectors.trigger), elTrigger => {
        if (getAttrOption(elTrigger, 'rel', that.options.selector, '') === that.options.id) {
            elTriggers.push(elTrigger);
        }
    });

    return elTriggers;
};

/**
 * Preload
 */
Gallery.prototype.preload = function () {
    const that = this;

    // Elements
    const elCarousel = select(that.selectors.carousel, that.el);
    const elImgs = selectAll('img[data-src]', elCarousel);

    // Classes
    const classLazy = className(that.selectors.vLazy);
    const classLazyPreload = className(that.selectors.vLazyPreload);

    // Force load of lazy images
    if (that.options.lazy) {
        each(elImgs, elImg => {
            if (!hasClass(classLazy)) {
                addClass(elImg, [classLazy, classLazyPreload]);
            }
        });
    }
};

/**
 * Listen Mouse Wheel
 */
Gallery.prototype.listenMouseWheel = function () {
    const that = this;

    // Start listening events
    that.wheelEvents = new WheelEvents(that.el, { axis: 'y' });

    // Listener - Top with Intent
    that.events.on(that.el, 'onWheelUpIntent', () => {
        that.events.trigger(that.el, 'handleHide');
    });

    // Listener - Bottom with Intent
    that.events.on(that.el, 'onWheelDownIntent', () => {
        that.events.trigger(that.el, 'handleHide');
    });
};

/**
 * Return true if the elements have the same 'ref'
 *
 * @param {Node} el
 *
 * @returns {*}
 */
Gallery.prototype.getRef = function (el) {
    const that = this;

    return getAttrOption(el, 'ref', that.options.selector, false);
};

/**
 * Return true if the elements have the same 'ref'
 *
 * @param {Node} el1
 * @param {Node} el2
 *
 * @returns {boolean}
 */
Gallery.prototype.isSameRef = function (el1, el2) {
    const that = this;

    const refEl1 = that.getRef(el1);
    const refEl2 = that.getRef(el2);
    return refEl1 === refEl2 && refEl1 && refEl2;
};

/**
 * Show
 */
Gallery.prototype.show = function (ref) {
    const that = this;

    // Elements
    const elTriggers = that.getTriggers();
    const elCarousel = select(that.selectors.carousel, that.el);
    const elCarouselSlides = selectAll(that.selectors.carouselSlide, elCarousel);
    const elOverlay = select(that.selectors.overlay, that.el);
    const elGhost = select(that.selectors.ghost, that.el);
    const elGhostPlaceholder = select(that.selectors.ghostPlaceholder, that.el);
    const elMirror = that.options.mirror ? select(that.options.mirror) : null;
    const elMirrorSlides = elMirror ? selectAll(`${Carousel.SELECTOR}__slide`, elMirror) : [];
    const elNavigation = select(that.selectors.navigation, that.el);

    // Classes
    const classLoaded = className(that.selectors.vLoaded);
    const classFill = className(that.selectors.vFill);
    const classActive = className(that.selectors.vActive);

    // Variables
    that.slideSelected = elCarouselSlides[0];

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {

            that.events.trigger(that.el, 'onShowStart');
            that.animating = true;
            that.open = true;
            scrollLock(true);
            Tweener.set([elCarousel, elOverlay], { opacity: 0 });
            show(that.el);

            // Listener - onSelect
            that.events.on(elCarousel, 'onSelect', e => {
                that.slideSelected = e.detail.el;

                // Another carousel might in sync with the gallery
                if (that.options.mirror && !that.animating) {
                    each(elMirrorSlides, (elMirrorSlide, i) => {
                        if (that.isSameRef(elMirrorSlide, that.slideSelected)) {
                            that.events.trigger(elMirror, 'handleJump', { index: i });
                            return;
                        }
                    });
                }
            });

            // Find the start index from ref
            let startIndex = 0;
            if (ref) {
                each(elCarouselSlides, (elCarouselSlide, i) => {
                    if (that.getRef(elCarouselSlide) === ref) {
                        startIndex = i;
                        return;
                    }
                });
            }

            // The carousel needs to be refreshed in order to make sure the slides
            // are correctly positionned
            that.events.trigger(elCarousel, 'handleRefresh', { index: startIndex });

            resolve();
        });
    };

    // Fct - Preload
    const preload = () => {
        return new Promise(resolve => {
            that.preload();
            resolve();
        });
    };

    // Fct - Zoom In
    const zoomIn = (elImgSource, elImgDestination) => {
        return new Promise(resolve => {

            // Scroll position
            const scroll = scrollPosition();

            // Image Size
            const imgSourceSize = imgSize(elImgSource);
            const imgDestinationSize = imgSize(elImgDestination);

            // Position Source
            const positionSource = {
                width: imgSourceSize.width,
                height: imgSourceSize.height,
                x: offset(elImgSource).left + imgSourceSize.left - scroll.x,
                y: offset(elImgSource).top + imgSourceSize.top - scroll.y
            };

            // Position Destination
            const positionDestination = {
                width: imgDestinationSize.width,
                height: imgDestinationSize.height,
                x: offset(elImgDestination).left + imgDestinationSize.left - scroll.x,
                y: offset(elImgDestination).top + imgDestinationSize.top - scroll.y
            };

            // Create ghost
            const elGhostImg = clone(elImgSource);
            removeClasses(elGhostImg);
            addClass(elGhostImg, classFill);
            removeChildren(elGhost);
            appendChild(elGhost, elGhostImg);

            // Position placeholder
            Tweener.set(elGhostPlaceholder, {
                width: positionSource.width,
                height: positionSource.height,
                x: positionSource.x,
                y: positionSource.y
            });

            // The ghost needs to be fully loaded before executing the Tween
            imageLoaded(elGhost).then(() => {

                // Tween to destination
                new Tweener({ speed: that.options.speedZoom, ease: 'power1.inOut' })
                    .add(elGhost, {
                        width: { from: positionSource.width, to: positionDestination.width },
                        height: { from: positionSource.height, to: positionDestination.height },
                        x: { from: positionSource.x, to: positionDestination.x },
                        y: { from: positionSource.y, to: positionDestination.y }
                    })
                    .add(elOverlay, {
                        opacity: { to: 1 }
                    })
                    .onStart(() => {
                        show([elGhost, elGhostPlaceholder]);
                    })
                    .play()
                    .then(() => {
                        hide([elGhost, elGhostPlaceholder]);
                        Tweener.clear([elOverlay, elCarousel], 'opacity');
                        Tweener.clear(elGhost, ['width', 'height', 'x', 'x']);
                        resolve();
                    });
            });
        });
    };

    // Fct - Show In
    const showIn = () => {
        return new Promise(resolve => {
            Tweener.set([elCarousel, elOverlay], { opacity: 1 });
            resolve();
        });
    };

    // Fct - Open
    const open = () => {
        return new Promise(resolve => {

            // Compute the source & destination images
            const elImgDestination = select('img', that.slideSelected);

            let elImgSource = null;
            each(elTriggers, elTrigger => {
                if (that.isSameRef(elTrigger, that.slideSelected)) {
                    elImgSource = select('img', elTrigger);
                    return;
                }
            });

            Tweener.set(elCarousel, { opacity: 0 });

            // Effect = Zoom
            if (that.options.effect === 'zoom' && hasClass(that.slideSelected, classLoaded) && elImgSource && elImgDestination) {
                zoomIn(elImgSource, elImgDestination).then(() => {
                    resolve();
                });

            // No effect
            } else {
                showIn().then(() => {
                    resolve();
                });
            }
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            that.animating = false;
            if (elNavigation) {
                addClass(elNavigation, classActive);
            }
            that.events.trigger(that.el, 'onShow');
            resolve();
        });
    };

    // Init & Proceed
    init().then(() => {
        preload().then(() => {
            sleep(that.options.delayShow).then(() => {
                open().then(() => {
                    done();
                });
            });
        });
    });
};

/**
 * Hide
 */
Gallery.prototype.hide = function () {
    const that = this;

    // Elements
    const elTriggers = that.getTriggers();
    const elCarousel = select(that.selectors.carousel, that.el);
    const elOverlay = select(that.selectors.overlay, that.el);
    const elGhost = select(that.selectors.ghost, that.el);
    const elGhostPlaceholder = select(that.selectors.ghostPlaceholder, that.el);
    const elNavigation = select(that.selectors.navigation, that.el);

    // Classes
    const classLoaded = className(that.selectors.vLoaded);
    const classFill = className(that.selectors.vFill);
    const classActive = className(that.selectors.vActive);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            that.animating = true;
            that.open = false;
            if (elNavigation) {
                removeClass(elNavigation, classActive);
            }
            that.events.trigger(that.el, 'onHideStart');
            resolve();
        });
    };

    // Fct - Zoom Out
    const zoomOut = (elImgSource, elImgDestination) => {
        return new Promise(resolve => {

            // Scroll position
            const scroll = scrollPosition();

            // Image Size
            const imgSourceSize = imgSize(elImgSource);
            const imgDestinationSize = imgSize(elImgDestination);

            // Position Source
            const positionSource = {
                width: imgSourceSize.width,
                height: imgSourceSize.height,
                x: offset(elImgSource).left + imgSourceSize.left - scroll.x,
                y: offset(elImgSource).top + imgSourceSize.top - scroll.y
            };

            // Position Destination
            const positionDestination = {
                width: imgDestinationSize.width,
                height: imgDestinationSize.height,
                x: offset(elImgDestination).left + imgDestinationSize.left - scroll.x,
                y: offset(elImgDestination).top + imgDestinationSize.top - scroll.y
            };

            // Create ghost
            const elGhostImg = clone(elImgSource);
            removeClasses(elGhostImg);
            addClass(elGhostImg, classFill);
            removeChildren(elGhost);
            appendChild(elGhost, elGhostImg);

            // Position placeholder
            Tweener.set(elGhostPlaceholder, {
                width: positionDestination.width,
                height: positionDestination.height,
                x: positionDestination.x,
                y: positionDestination.y
            });

            // The ghost needs to be fully loaded before executing the Tween
            imageLoaded(elGhost).then(() => {

                // Tween to destination
                new Tweener({ speed: that.options.speedZoom, ease: 'power1.inOut' })
                    .add(elGhost, {
                        width: { from: positionSource.width, to: positionDestination.width },
                        height: { from: positionSource.height, to: positionDestination.height },
                        x: { from: positionSource.x, to: positionDestination.x },
                        y: { from: positionSource.y, to: positionDestination.y }
                    })
                    .add(elOverlay, {
                        opacity: { to: 0, ease: 'power2.in' }
                    })
                    .onStart(() => {
                        hide(elCarousel);
                        show([elGhost, elGhostPlaceholder]);
                    })
                    .play()
                    .then(() => {
                        hide([elGhost, elGhostPlaceholder, that.el]);
                        show(elCarousel);
                        Tweener.clear(elOverlay, 'opacity');
                        Tweener.clear(elGhost, ['width', 'height', 'x', 'x']);
                        resolve();
                    });            });
        });
    };

    // Fct - Close
    const close = () => {
        return new Promise(resolve => {

            // Compute the source & destination images
            const elImgSource = select('img', that.slideSelected);
            let elImgDestination = null;
            each(elTriggers, elTrigger => {
                if (that.isSameRef(elTrigger, that.slideSelected)) {
                    elImgDestination = select('img', elTrigger);
                    return;
                }
            });

            // Effect = Zoom
            if (that.options.effect === 'zoom' && hasClass(that.slideSelected, classLoaded) && elImgSource && elImgDestination) {
                zoomOut(elImgSource, elImgDestination).then(() => {
                    resolve();
                });

            // No effect
            } else {
                resolve();
            }
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            that.animating = false;
            scrollLock(false);
            that.events.off(elCarousel, 'onSelect');
            hide(that.el);
            that.events.trigger(that.el, 'onHide');
            resolve();
        });
    };

    // Init & Proceed
    init().then(() => {
        sleep(that.options.delayHide).then(() => {
            close().then(() => {
                done();
            });
        });
    });
};

/**
 * Destroy
 */
Gallery.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    that.carousel.destroy();
    if (that.wheelEvents) {
        that.wheelEvents.destroy();
    }
};

/**
 * Constants
 */
Gallery.SELECTOR = '.js-gallery';
Gallery.LAZY = false;
Gallery.PRELOAD = false;
Gallery.EFFECT = 'none';
Gallery.SPEED_ZOOM = 500;
Gallery.DELAY_SHOW = 0;
Gallery.DELAY_HIDE = 0;
Gallery.WHEEL = true;

export default Gallery;
