import addClass from '../fn/attributes/addClass';
import getAttr from '../fn/attributes/getAttr';
import hasClass from '../fn/attributes/hasClass';
import removeClass from '../fn/attributes/removeClass';
import redirect from '../fn/browser/redirect';
import serialize from '../fn/form/serialize';
import html from '../fn/manipulation/html';
import select from '../fn/select/select';
import selectAll from '../fn/select/selectAll';
import each from '../helpers/collection/each';
import isObject from '../helpers/lang/isObject';
import parseBool from '../helpers/lang/parseBool';
import get from '../helpers/object/get';
import setUndefined from '../helpers/object/setUndefined';
import isJson from '../helpers/string/isJson';
import parseJson from '../helpers/string/parseJson';
import className from '../helpers/utils/className';
import hide from '../methods/animate/hide';
import show from '../methods/animate/show';
import ajax from '../methods/ajax/ajax';
import attrOption from './.internal/options/attrOption';
import getAttrOption from './.internal/options/getAttrOption';
import EventsManager from './EventsManager';
import Tweener from './Tweener';

/*
 * Coral Popin main functionalities
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const Popin = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: Popin.SELECTOR,

        // Options
        type: Popin.TYPE,
        debug: Popin.DEBUG
    });

    // Set Options from Attr
    options = attrOption(options, el, ['popin', 'type', 'debug'], options.selector);

    // Boolean values
    options.debug = parseBool(options.debug);

    // Selectors
    const selectors = {

        // Statuses
        vLoading: '.v--loading',
        vPreloading: '.v--preloading',
        vReady: '.v--ready',
        vHidden: '.v--hidden',

        // Elements
        triggerShow: `${options.selector}__trigger-show`,
        triggerSwap: `${options.selector}__trigger-swap`,

        overlay: `${options.selector}__overlay`,
        hide: `${options.selector}__hide`,
        main: `${options.selector}__main`,
        title: `${options.selector}__title`,
        form: `${options.selector}__form`,
        ajax: `${options.selector}__ajax`,
        error: `${options.selector}__error`,
        cta: `${options.selector}__cta`,
        userError: `${options.selector}__user-error`,
        loading: `${options.selector}__loading`,
        loadingTitle: `${options.selector}__loading-title`,
        loadingTitleProgress: `${options.selector}__loading-title-progress`,
        ready: `${options.selector}__ready`,
        preload: `${options.selector}__preload`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
Popin.prototype.init = function () {
    const that = this;

    // Elements
    const elTriggerShows = selectAll(that.selectors.triggerShow, that.el);
    const elPreloads = selectAll(that.selectors.preload, that.el);

    // Variables
    that.elPopin = null;

    // Listener - Click / Trigger Show
    each(elTriggerShows, elTrigger => {
        that.events.on(elTrigger, 'click', e => {
            e.preventDefault();
            that.show(select(getAttrOption(elTrigger, 'popin', that.options.selector, null)));
        });
    });

    // Preloads
    each(elPreloads, elPreload => {
        that.preload(elPreload);
    });
};

/**
 * Preload
 */
Popin.prototype.preload = function (el) {
    const that = this;

    // Elements
    const elLoading = select(that.selectors.loading, el);
    const elLoadingTitle = select(that.selectors.loadingTitle, el);
    const elLoadingTitleProgress = select(that.selectors.loadingTitleProgress, elLoadingTitle);

    // Classes
    const classLoading = className(that.selectors.vLoading);
    const classPreloading = className(that.selectors.vPreloading);

    // Fct - Loading
    const loading = () => {
        addClass([elLoading, elLoadingTitle], classLoading);
        addClass(el, classPreloading);
        if (elLoadingTitleProgress) {
            new Tweener({ speed: 1500, ease: 'power3.inOut' })
                .add(elLoadingTitleProgress, {
                    scaleX: { from: 0, to: 0.8 },
                    x: { from: '-50%', to: '-10%' }
                })
                .play();
        }
    };

    // Fct - Loaded
    const loaded = () => {
        removeClass([elLoading, elLoadingTitle], classLoading);
        removeClass(el, classPreloading);
    };

    // Fct - Handle Request
    const handleRequest = (url, method) => {
        loading();

        ajax(url, method)

            // Success - Render the HTML
            .then(success => {
                loaded();
                html(el, success);
                that.events.trigger(el, 'onPreload');
            })

            // Error
            .catch(error => {
                loaded();
                if (that.options.debug) {
                    console.log(error);
                }
            });
    };

    // Handle AJAX
    handleRequest(getAttrOption(el, 'url', that.options.selector, ''), getAttrOption(el, 'method', that.options.selector, 'get'));
};

/**
 * Show
 *
 * @param {Node} el
 */
Popin.prototype.show = function (el) {
    const that = this;

    // Elements
    const elOverlay = select(that.selectors.overlay, that.el);

    // Classes
    const classHidden = className(that.selectors.vHidden);
    const classPreloading = className(that.selectors.vPreloading);

    // Fct - Listen
    const listen = () => {
        if (hasClass(that.elPopin, classPreloading)) {
            that.events.on(that.elPopin, 'onPreload', () => {
                that.listen();
            });
        } else {
            that.listen();
        }
    };

    // Fct - Popin -> Show
    const popinShow = () => {
        return new Promise(resolve => {

            // Save Current Popin
            that.elPopin = el;
            listen();

            // Show Overlay
            show(elOverlay, 'fade', { speed: 300 });

            // Show Popin
            if (that.options.type === 'sidebar') {
                new Tweener({ speed: 300 })
                    .add(that.elPopin, {
                        x: { from: '100%', to: 0 }
                    })
                    .onStart(() => {
                        removeClass(that.elPopin, classHidden);
                    })
                    .play()
                    .then(() => {
                        Tweener.clear(that.elPopin, 'x');
                        resolve();
                    });
            } else {
                removeClass(that.elPopin, classHidden);
                resolve();
            }
        });
    };

    // Fct - Popin -> Swap
    const popinSwap = () => {
        return new Promise(resolve => {

            // Remove Current Popin
            that.unlisten();
            addClass(that.elPopin, classHidden);

            // Save Current Popin
            that.elPopin = el;
            listen();
            removeClass(that.elPopin, classHidden);
            resolve();
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            that.events.trigger(that.elPopin, 'onShow');
        });
    };

    // Show Popin
    if (that.elPopin) {
        popinSwap().then(() => {
            done();
        });
    } else {
        popinShow().then(() => {
            done();
        });
    }
};

/**
 * Listen
 */
Popin.prototype.listen = function () {
    const that = this;

    // Elements
    const elTriggerSwaps = selectAll(that.selectors.triggerSwap, that.elPopin);
    const elHides = selectAll(that.selectors.hide, that.elPopin);
    const elMain = select(that.selectors.main, that.elPopin);
    const elTitle = select(that.selectors.title, that.elPopin);
    const elForm = select(that.selectors.form, that.elPopin);
    const elAjax = select(that.selectors.ajax, that.el);
    const elError = select(that.selectors.error, that.el);
    const elCta = select(that.selectors.cta, that.elPopin);
    const elUserError = select(that.selectors.userError, that.elPopin);
    const elOverlay = select(that.selectors.overlay, that.el);
    const elLoading = select(that.selectors.loading, that.elPopin);
    const elLoadingTitle = select(that.selectors.loadingTitle, that.elPopin);
    const elLoadingTitleProgress = select(that.selectors.loadingTitleProgress, elLoadingTitle);
    const elReady = select(that.selectors.ready, that.elPopin);

    // Classes
    const classLoading = className(that.selectors.vLoading);
    const classReady = className(that.selectors.vReady);

    // Fct - Loading
    const loading = () => {
        addClass([elTitle, elForm, elCta, elLoading, elLoadingTitle, elMain, elOverlay], classLoading);
        if (elLoadingTitleProgress) {
            new Tweener({ speed: 1000, ease: 'power3.inOut' })
                .add(elLoadingTitleProgress, {
                    scaleX: { from: 0, to: 0.8 },
                    x: { from: '-50%', to: '-10%' }
                })
                .play();
        }
    };

    // Fct - Ready
    const ready = () => {
        removeClass(elLoading, classLoading);
        addClass(elReady, classReady);
        new Tweener({ speed: 125, ease: 'power1.in' })
            .add(elLoadingTitleProgress, {
                scaleX: { to: 1 },
                x: { to: 0 }
            })
            .play();
    };

    // Fct - Loaded
    const loaded = () => {
        removeClass([elTitle, elForm, elCta, elLoading, elLoadingTitle, elMain, elOverlay], classLoading);
    };

    // Fct - Handle Request
    const handleRequest = (url, method, data) => {
        loading();

        // Remove listener from current popin
        that.unlisten();

        ajax(url, method, data)

            // Success - Render the HTML or Redirect
            .then(success => {

                // JSON
                if (isJson(success)) {
                    success = parseJson(response);
                }

                // Success is an object, let's redirect
                if (isObject(success)) {
                    ready();
                    redirect(get(success.redirect, '') || window.location);

                // Render HTML
                } else {
                    loaded();
                    html(elAjax, success);
                    that.show(elAjax);
                }
            })

            // Error
            .catch(error => {
                loaded();
                that.show(elError);
                if (that.options.debug) {
                    console.log(error);
                }
            });
    };

    // Listener - Form
    if (elForm) {

        // Listener - Submit
        that.events.on(elForm, 'submit', e => {
            e.preventDefault();
            if (!hasClass(elForm, classLoading)) {
                handleRequest(getAttr(e.target, 'action'), getAttr(e.target, 'method'), serialize(e.target));
            }
        });

        // Listener - Handle Submit
        that.events.on(elForm, 'handleSubmit', e => {
            e.preventDefault();
            if (!hasClass(elForm, classLoading)) {
                handleRequest(getAttr(e.target, 'action'), getAttr(e.target, 'method'), serialize(e.target));
            }
        });
    }

    // Listener - Click / Trigger
    each(elTriggerSwaps, elTrigger => {
        that.events.on(elTrigger, 'click', e => {
            e.preventDefault();

            // Show or Ajax ?
            const popinID = getAttrOption(elTrigger, 'popin', that.options.selector, null);

            // Show
            if (popinID) {
                that.show(select(popinID));

            // Ajax
            } else {
                handleRequest(getAttrOption(elTrigger, 'url', that.options.selector, ''), getAttrOption(elTrigger, 'method', that.options.selector, 'get'));
            }
        });
    });

    // Listener - Click / Hides
    that.events.on(elHides, 'click', e => {
        e.preventDefault();
        that.hide();
    });

    // Listener - Click / Overlay
    that.events.on(elOverlay, 'click', e => {
        e.preventDefault();
        if (!hasClass(elOverlay, classLoading)) {
            that.hide();
        }
    });

    // Listener - Click / User Error
    if (elUserError) {
        that.events.on(elUserError, 'click', e => {
            e.preventDefault();
            hide(elUserError, 'slide');
        });
    }
};

/**
 * Unlisten
 */
Popin.prototype.unlisten = function () {
    const that = this;

    // Elements
    const elTriggerSwaps = selectAll(that.selectors.triggerSwap, that.elPopin);
    const elHides = selectAll(that.selectors.hide, that.elPopin);
    const elForm = select(that.selectors.form, that.elPopin);
    const elOverlay = select(that.selectors.overlay, that.el);

    // Listener - Form
    if (elForm) {
        that.events.off(elForm, 'submit');
        that.events.off(elForm, 'handleSubmit');
    }

    // Listener - Click / Triggers
    each(elTriggerSwaps, elTrigger => {
        that.events.off(elTrigger, 'click');
    });

    // Listener - Click / Overlay
    that.events.off(elOverlay, 'click');

    // Listener - Click / Hides
    that.events.off(elHides, 'click');
};

/**
 * Hide
 */
Popin.prototype.hide = function () {
    const that = this;

    // Elements
    const elOverlay = select(that.selectors.overlay, that.el);

    // Classes
    const classHidden = className(that.selectors.vHidden);

    // Fct - Popin -> Hide
    const popinHide = () => {
        return new Promise(resolve => {

            // Remove listeners
            that.unlisten();

            // Hide Overlay
            hide(elOverlay, 'fade', { speed: 300 });

            // Hide Popin
            if (that.options.type === 'sidebar') {
                new Tweener({ speed: 300 })
                    .add(that.elPopin, {
                        x: { to: '100%' }
                    })
                    .play().then(() => {
                        addClass(that.elPopin, classHidden);
                        Tweener.clear(that.elPopin, 'x');
                        resolve();
                    });
            } else {
                addClass(that.elPopin, classHidden);
                resolve();
            }
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            that.events.trigger(that.elPopin, 'onHide');
            that.elPopin = null;
        });
    };

    // Check if the popin is actually visible
    if (that.elPopin) {
        popinHide().then(() => {
            done();
        });
    }
};

/**
 * Constants
 */
Popin.SELECTOR = '.js-popin';
Popin.TYPE = 'sidebar'; // sidebar, modal
Popin.DEBUG = false;

export default Popin;
