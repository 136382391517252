"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Is an JSON formated string ?
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {string} str
 *
 * @returns {boolean}
 */
function default_1(str) {
    if (typeof str !== 'string') {
        return false;
    }
    try {
        var result = JSON.parse(str);
        var type = Object.prototype.toString.call(result);
        return type === '[object Object]' || type === '[object Array]';
    }
    catch (err) {
        return false;
    }
}
exports.default = default_1;
