import attrOption from './.internal/options/attrOption';
import getAttrOption from './.internal/options/getAttrOption';
import EventsManager from './EventsManager';
import removeClass from '../fn/attributes/removeClass';
import submit from '../fn/form/submit';
import select from '../fn/select/select';
import selectAll from '../fn/select/selectAll';
import selectParent from '../fn/select/selectParent';
import selectFormInputs from '../fn/select/selectFormInputs';
import each from '../helpers/collection/each';
import parseBool from '../helpers/lang/parseBool';
import setUndefined from '../helpers/object/setUndefined';
import className from '../helpers/utils/className';

/*
 * Form utility
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const Formidable = function (el, options) {
    const that = this;

    options = options || {};

    // Options - Selector
    setUndefined(options, {
        selector: Formidable.SELECTOR,

        // Options
        autosubmit: Formidable.AUTOSUBMIT
    });

    // Set Options from Attr
    options = attrOption(options, el, ['autosubmit'], options.selector);

    // Boolean values
    options.autosubmit = parseBool(options.autosubmit);

    // Selectors
    const selectors = {

        // Statuses
        vError: '.v--error',

        // Elements
        submit: `${options.selector}__submit`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.events = new EventsManager();
    that.selectors = selectors;

    // Init
    that.init();
};

/**
 * Init
 */
Formidable.prototype.init = function () {
    const that = this;

    // Elements
    const elSubmits = selectAll(that.selectors.submit);
    const elInputs = selectFormInputs(that.el);

    // Classes
    const classError = className(that.selectors.vError);

    // Autosubmit
    if (that.options.autosubmit) {
        that.initAutosubmit();
    }

    // Filter the submits
    const elSubmitsFiltered = [];
    each(elSubmits, elSubmit => {
        if (select(getAttrOption(elSubmit, 'target', that.options.selector, null)) === that.el || selectParent(elSubmit, 'form') === that.el) {
            elSubmitsFiltered.push(elSubmit);
        }
    });

    // Listener - Submit
    each(elSubmitsFiltered, elSubmit => {
        that.events.on(elSubmit, 'click', e => {
            e.preventDefault();

            // Trigger an event
            if (parseBool(getAttrOption(elSubmit, 'event', that.options.selector, false))) {
                that.events.trigger(that.el, 'handleSubmit');

            // Submit
            } else {
                submit(that.el);
            }
        });
    });

    // Listener - Focus /  Error Inputs
    each(elInputs, elInput => {
        that.events.on(elInput, 'focus', e => {
            removeClass(elInput, classError);
        });
    });
};

/**
 * Init Autosubmit
 */
Formidable.prototype.initAutosubmit = function () {
    const that = this;

    // Elements
    const elFormInputs = selectFormInputs(that.el);

    // Listener - Select change
    that.events.on(elFormInputs, 'change', e => {
        e.preventDefault();
        submit(that.el);
    });
};

/**
 * Destroy
 */
Formidable.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
Formidable.SELECTOR = '.js-formidable';
Formidable.AUTOSUBMIT = false;

export default Formidable;
