import isString from '../../helpers/lang/isString';

/*
 * Select matching Element By Tag Name from the DOM and return it.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {string} className
 * @param {Node} context
 *
 * @returns {NodeList|null}
 */
export default function (name, context) {

    // Name provided & is a String
    return (name && isString(name)) ? (context || document).getElementsByTagName(name) : null;
}
