import Reef from '../../Reef';

/*
 * Add show to Reef.methods.defaults.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 */

Reef.methods.defaults.show = {
    speed:  400,
    ease: 'Power1.easeOut'
};
