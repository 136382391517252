import EventsManager from './EventsManager';
import swapClass from '../fn/attributes/swapClass';
import select from '../fn/select/select';
import setUndefined from '../helpers/object/setUndefined';
import className from '../helpers/utils/className';

/*
 * Check if the device is touchable or not. Reacts to touch events to determine if
 * a device is touchable. Adds 'touch' & 'no-touch' to the document & dispatch
 * events when touchable is defined.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const Touchable = function (el, options) {
    const that = this;

    options = options || {};

    // Options - Selector
    setUndefined(options, {
        selector: Touchable.SELECTOR
    });

    // Selectors
    const selectors = {

        // States
        touch: Touchable.TOUCH,
        noTouch: Touchable.NO_TOUCH
    };

    // Element & Options
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
Touchable.prototype.init = function () {
    const that = this;

    // Classes
    const classTouch = className(that.selectors.touch);
    const classNoTouch = className(that.selectors.noTouch);

    // Status
    that.isTouch = false;

    // Elements
    const elHtml = select('html', document);

    // Detect touch events
    that.events.once(window, 'touchstart', () => {

        // Update touch status
        that.isTouch = true;

        // Add class to 'html' element
        swapClass(elHtml, classNoTouch, classTouch);

        // Trigger event - Touch
        that.events.trigger(document, 'onTouchable');
    });

    // Detect mouseover events. If the device is not already classified as a touch
    // device, it should mean that it is a device with a mouse
    that.events.once(window, 'mouseover', () => {

        // Not already a touch device
        if (!that.isTouch) {

            // Add class to 'html' element
            swapClass(elHtml, classTouch, classNoTouch);

            // Trigger event - No Touch
            that.events.trigger(document, 'onNoTouchable');
        }
    });
};

/**
 * Destroy
 */
Touchable.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
Touchable.SELECTOR = '.js-touchable';
Touchable.TOUCH = '.touch';
Touchable.NO_TOUCH = '.no-touch';

export default Touchable;
