import attrOption from './.internal/options/attrOption';
import EventsManager from './EventsManager';
import ScrollVibe from './ScrollVibe';
import Tweener from './Tweener';
import addClass from '../fn/attributes/addClass';
import getClasses from '../fn/attributes/getClasses';
import removeClass from '../fn/attributes/removeClass';
import append from '../fn/manipulation/append';
import appendChild from '../fn/manipulation/appendChild';
import create from '../fn/manipulation/create';
import empty from '../fn/manipulation/empty';
import html from '../fn/manipulation/html';
import text from '../fn/manipulation/text';
import select from '../fn/select/select';
import selectAll from '../fn/select/selectAll';
import each from '../helpers/collection/each';
import parseBool from '../helpers/lang/parseBool';
import setUndefined from '../helpers/object/setUndefined';
import isHtml from '../helpers/string/isHtml';
import trim from '../helpers/string/trim';
import className from '../helpers/utils/className';
import sleep from '../methods/utils/sleep';

/*
 * Reveal texts in an elegant way.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const TextReveal = function (el, options) {
    const that = this;

    options = options || {};

    // Options - Selector
    setUndefined(options, {
        selector: TextReveal.SELECTOR,

        // Options
        autostart: TextReveal.AUTOSTART,
        mode: TextReveal.MODE,
        split: TextReveal.SPLIT,
        animation: TextReveal.ANIMATION,
        speedWord: TextReveal.SPEED_WORD,
        speedLetter: TextReveal.SPEED_LETTER,
        lagWord: TextReveal.LAG_WORD,
        lagLetter: TextReveal.LAG_LETTER,
        ease: TextReveal.EASE,
        delay: TextReveal.DELAY,
        reverse: TextReveal.REVERSE,
        debug: TextReveal.DEBUG
    });

    // Set Options from Attr
    options = attrOption(options, el, ['split', 'animation', 'mode', 'ease', 'delay', 'autostart', 'start', 'trigger', 'reverse', 'debug'], options.selector);

    // Boolean values
    options.autostart = parseBool(options.autostart);
    options.debug = parseBool(options.debug);
    options.reverse = parseBool(options.reverse);

    // Speed depends on animation type
    options.speed = options.split === 'word' ? options.speedWord : options.speedLetter;
    options.lag = options.split === 'word' ? options.lagWord : options.lagLetter;

    // Set Options from Attr
    options = attrOption(options, el, ['speed', 'lag'], options.selector);

    // Selectors
    const selectors = {

        // Animations
        vAnimationUp: '.v--animation-up',

        // Elements
        animation: `${options.selector}__animation`,
        animationWrapper: `${options.selector}__animation-wrapper`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    if (that.options.autostart) {
        that.init();
    }
};

/**
 * Init
 */
TextReveal.prototype.init = function () {
    const that = this;

    // Classes
    const classAnimation = className(that.selectors.animation);
    const classAnimationWrapper = className(that.selectors.animationWrapper);

    // Hide element
    Tweener.set(that.el, { opacity: 0 });

    // The sentence can include HTML elements. In that case, they need to be treated
    // differently to avoid any problems
    let words = [];
    each([...(new DOMParser().parseFromString(html(that.el), 'text/html')).body.childNodes].map(child => child.outerHTML || child.textContent), fragment => {
        if (fragment === '<br>') {
            words.push('<br>');
        } else if (isHtml(fragment)) {
            const el = new DOMParser().parseFromString(trim(fragment), 'text/html').body.childNodes[0];
            each(text(el).split(' '), wrd => {
                words.push(create('span', { html: wrd, classNames: getClasses(el) }).outerHTML);
            });
        } else {
            words = words.concat(trim(fragment).split(' '));
        }
    });

    // Empty the element & replace with spanned items
    empty(that.el);
    each(words, (word, i) => {

        // Split by word. All spans will be filled with the splitted text with a space
        // added after each span
        if (that.options.split === 'word') {

            // <br> need to be treated differently
            if (word === '<br>') {
                appendChild(that.el, create('br'));
            } else {
                appendChild(that.el, create('span', { classNames: classAnimationWrapper }, create('sub', { html: word, classNames: classAnimation })));
            }

        // Split by letter. For each word a span is created. Within this span, letters
        // are added in order to avoid splitted words
        } else if (that.options.split === 'letter') {

            // <br> need to be treated differently
            if (word === '<br>') {
                appendChild(that.el, create('br'));
            } else {

                if (isHtml(word)) {
                    const el = new DOMParser().parseFromString(word, 'text/html').body.childNodes[0];
                    const classes = getClasses(el);
                    classes.push(classAnimationWrapper);

                    each(text(el).split(''), letter => {
                        appendChild(that.el, create('span', { classNames: classes }, create('sub', { html: letter, classNames: classAnimation })));
                    });

                } else {
                    each(word.split(''), letter => {
                        appendChild(that.el, create('span', { classNames: classAnimationWrapper }, create('sub', { html: letter, classNames: classAnimation })));
                    });
                }
            }
        }

        if (i < words.length - 1) {
            append(that.el, ' ');
        }
    });

    // Listener - Reveal
    if (that.options.reverse) {
        that.events.on(that.el, 'handleReveal', () => {
            sleep(that.options.delay).then(() => {
                that.reveal();
            });
        });
        that.events.on(that.el, 'handleUnreveal', () => {
            that.unreveal();
        });

    } else {
        that.events.once(that.el, 'handleReveal', () => {
            sleep(that.options.delay).then(() => {
                that.reveal();
            });
        });
    }

    // Mode = Load
    if (that.options.mode === 'load') {
        that.events.trigger(that.el, 'handleReveal');

    // Mode = Scroll
    } else if (that.options.mode === 'scroll') {
        that.vibes.push(new ScrollVibe(that.el, {
            debug: that.options.debug,
            trigger: that.options.trigger ? select(that.options.trigger) : that.el,
            do: {
                start: that.options.start,
                event: {
                    on: 'handleReveal',
                    off: 'handleUnreveal'
                }
            }
        }));
    }
};

/**
 * Trigger 'onReveal'
 */
TextReveal.prototype.onReveal = function () {
    const that = this;
    that.events.trigger(that.el, 'onReveal');
};

/**
 * Trigger 'onUnreveal'
 */
TextReveal.prototype.onUnreveal = function () {
    const that = this;
    that.events.trigger(that.el, 'onUnreveal');
};

/**
 * Reveal
 */
TextReveal.prototype.reveal = function () {
    const that = this;

    // Up
    if (that.options.animation === 'up') {
        that.revealUp();

    // Wave
    } else if (that.options.animation === 'wave') {
        that.revealWave();

    // Type
    } else if (that.options.animation === 'type') {
        that.revealType();
    }
};

/**
 * Unreveal
 */
TextReveal.prototype.unreveal = function () {
    const that = this;

    // Up
    if (that.options.animation === 'up') {
        that.unrevealUp();
    }
};

/**
 * Reveal - Up
 */
TextReveal.prototype.revealUp = function () {
    const that = this;

    // Elements
    const elAnimations = selectAll(that.selectors.animation, that.el);

    // Classes
    const classAnimation = className(that.selectors.vAnimationUp);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            addClass(that.el, classAnimation);
            resolve();
        });
    };

    // Fct - Reveal
    const reveal = () => {
        return new Promise(resolve => {
            const tween = new Tweener({ speed: that.options.speed, ease: that.options.ease });
            each(elAnimations, (el, i) => {
                tween.add(el, {
                    y: { from: '100%', to: 0, delay: that.options.lag * i }
                });
            });
            tween
                .onStart(() => {
                    Tweener.set(that.el, { opacity: 1 });
                })
                .play(() => {
                    resolve();
                });
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            removeClass(that.el, classAnimation);
            Tweener.clear(elAnimations, ['y']);
            that.onReveal();
            resolve();
        });
    };

    // Init & proceed
    init().then(() => {
        reveal().then(() => {
            done();
        });
    });
};

/**
 * Reveal - Wave
 */
TextReveal.prototype.revealWave = function () {
    const that = this;

    // Elements
    const elAnimations = selectAll(that.selectors.animation, that.el);

    // Fct - Reveal
    const reveal = () => {
        return new Promise(resolve => {
            const tween = new Tweener({ speed: that.options.speed, ease: that.options.ease });
            each(elAnimations, (el, i) => {
                tween.add(el, {
                    scale: { from: 0, to: 1, delay: that.options.lag * i },
                    y: { from: '80%', to: 0, delay: that.options.lag * i },
                    opacity: { from: 0, to: 1, delay: that.options.lag * i },
                });
            });
            tween
                .onStart(() => {
                    Tweener.set(that.el, { opacity: 1 });
                })
                .play(() => {
                    resolve();
                });
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            Tweener.clear(elAnimations, ['y', 'scale']);
            that.onReveal();
            resolve();
        });
    };

    // Init & proceed
    reveal().then(() => {
        done();
    });
};

/**
 * Reveal - Type
 */
TextReveal.prototype.revealType = function () {
    const that = this;

    // Elements
    const elAnimations = selectAll(that.selectors.animation, that.el);

    // Fct - Reveal Animation
    const revealAnimation = (el, lag) => {
        return new Promise(resolve => {
            Tweener.set(el, { opacity: 0 });
            sleep(lag).then(() => {
                Tweener.set(el, { opacity: 1 });
                resolve();
            });
        });
    };

    // Fct - Reveal
    const reveal = () => {
        return new Promise(resolve => {
            const promises = [];
            each(elAnimations, (el, i) => {
                promises.push(revealAnimation(el, that.options.lag * i));
            });
            Tweener.set(that.el, { opacity: 1 });
            Promise.all(promises).then(() => {
                resolve();
            });
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            that.onReveal();
            resolve();
        });
    };

    // Init & proceed
    reveal().then(() => {
        done();
    });
};

/**
 * Unreveal - Up
 */
TextReveal.prototype.unrevealUp = function () {
    const that = this;

    // Elements
    const elAnimations = selectAll(that.selectors.animation, that.el);

    // Classes
    const classAnimation = className(that.selectors.vAnimationUp);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            addClass(that.el, classAnimation);
            resolve();
        });
    };

    // Fct - uneveal
    const unreveal = () => {
        return new Promise(resolve => {
            const tween = new Tweener({ speed: that.options.speed, ease: that.options.ease });
            each(elAnimations, (el, i) => {
                tween.add(el, {
                    y: { to: '-100%', delay: that.options.lag * (elAnimations.length - 1 - i) }
                });
            });
            tween.play(() => {
                resolve();
            });
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            Tweener.set(that.el, { opacity: 0 });
            Tweener.clear(elAnimations, ['y']);
            removeClass(that.el, classAnimation);
            that.onUnreveal();
            resolve();
        });
    };

    // Init & proceed
    init().then(() => {
        unreveal().then(() => {
            done();
        });
    });
};

/**
 * Destroy
 */
TextReveal.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
TextReveal.SELECTOR = '.js-textReveal';
TextReveal.AUTOSTART = true;
TextReveal.MODE = 'load'; // load, scroll, event
TextReveal.SPLIT = 'word'; // letter, word
TextReveal.ANIMATION = 'up'; // wave, up, type
TextReveal.SPEED_WORD = 350;
TextReveal.SPEED_LETTER = 250;
TextReveal.LAG_WORD = 50;
TextReveal.LAG_LETTER = 35;
TextReveal.DELAY = 0;
TextReveal.EASE = 'power2.out';
TextReveal.DEBUG = false;
TextReveal.REVERSE = false;

export default TextReveal;
