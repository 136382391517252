import selectAll from '../select/selectAll';
import each from '../../helpers/collection/each';

/*
 * Remove all the classed from node(s)
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string|Array} nodes
 * @param {Element} context
 */
export default function (nodes, context) {

    // Remove classes from chosen nodes
    each(selectAll(nodes, context), node => {
        node.className = '';
    });
}
