import each from '../../helpers/collection/each';
import isObject from '../../helpers/lang/isObject';

/*
 * Redirect page to another.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {string} url
 * @param {Object} qs
 */
export default function (url, qs) {

    qs = qs || {};

    const uri = new URL(url);

    // Format Query String if needed
    if (isObject(qs)) {
        each(qs, (value, key) => {
            uri.searchParams.set(key, value);
        });
    }

    // Redirect
    window.location.replace(uri.toString());
}
