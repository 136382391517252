import attrOption from './.internal/options/attrOption';
import ScrollVibe from './ScrollVibe';
import parent from '../fn/select/parent';
import each from '../helpers/collection/each';
import parseBool from '../helpers/lang/parseBool';
import setUndefined from '../helpers/object/setUndefined';

/*
 * Reveal images in an elegant way.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const ImageSmooth = function (el, options) {
    const that = this;

    options = options || {};

    // Options - Selector
    setUndefined(options, {
        selector: ImageSmooth.SELECTOR,

        // Options
        deltaIn: ImageSmooth.DELTA_IN,
        deltaOut: ImageSmooth.DELTA_OUT,
        ease: ImageSmooth.EASE,
        debug: ImageSmooth.DEBUG
    });

    // Set Options from Attr
    options = attrOption(options, el, ['deltaIn', 'deltaOut', 'ease', 'debug'], options.selector);

    // Boolean values
    options.debug = parseBool(options.debug);

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.vibes = [];

    // Init
    that.init();
};

/**
 * Init
 */
ImageSmooth.prototype.init = function () {
    const that = this;

    // Elements
    const elParent = parent(that.el);

    that.vibes.push(new ScrollVibe(elParent, {
        debug: that.options.debug,

        do: [

            // Smooth In/Out
            {
                target: that.el,
                start: 'top bottom',
                end: 'bottom top',
                imageLoaded: true,
                parallax: {
                    y: {
                        from: that.options.deltaIn,
                        to: that.options.deltaOut,
                        ease: that.options.ease
                    }
                }
            }
        ]
    }));
};

/**
 * Destroy
 */
ImageSmooth.prototype.destroy = function () {
    const that = this;

    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
ImageSmooth.SELECTOR = '.js-imageSmooth';
ImageSmooth.EASE = 'power0.inOut';
ImageSmooth.DELTA_IN = '-25%';
ImageSmooth.DELTA_OUT = '25%';
ImageSmooth.DEBUG = false;

export default ImageSmooth;
