import select from '../select/select';

/*
 * Clones a Node. To clone only the node and its attributes, pass false for the
 * `deep` argument. If `deep` = true, it creates a deep copy of the selected Node
 * including attributes and child elements.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {boolean} deep
 *
 * @returns {Node|null}
 */
export default function (node, deep) {

    // Select matching node
    node = select(node);

    // DOM node exists, clone the node
    return node ? node.cloneNode(deep) : null;
}
