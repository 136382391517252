import appendChildren from './appendChildren';
import setHtml from './setHtml';
import addClass from '../attributes/addClass';
import setAttr from '../attributes/setAttr';
import each from '../../helpers/collection/each';
import isArray from '../../helpers/lang/isArray';
import isString from '../../helpers/lang/isString';
import or from '../../helpers/lang/or';
import get from '../../helpers/object/get';

/*
 * Create an HTMLElement based on the specifications.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {string} tag
 * @param {Object} options
 * @param {Array} children
 *
 * @returns {HTMLElement}
 */
export default function (tag, options, children) {

    // Some tags must be created with a specific namespace. It is the case for SVG
    // icons for example
    let namespace = '';
    if (or(tag, 'svg', 'path', 'circle', 'polygon', 'g')) {
        namespace = 'http://www.w3.org/2000/svg';
    }

    // Create Node
    const node = namespace !== '' ? document.createElementNS(namespace, tag) : document.createElement(tag);

    // Options - Class Names
    const classNames = get(options, 'classNames', []);
    each((isString(classNames) ? classNames.split(' ') : classNames), className => {
        addClass(node, className);
    });

    // Options - ID
    const id = get(options, 'id', '');
    if (id !== '') {
        node.id = id;
    }

    // Options - Attributes
    each(get(options, 'attributes', {}), (value, attribute) => {
        setAttr(node, attribute, value);
    });

    // Options - HTML
    const html = get(options, 'html', '');
    if (html !== '') {
        setHtml(node, html);
    }

    // Children need to be appended to the newly create element
    if (children) {
        if (!isArray(children)) {
            children = [children];
        }
        appendChildren(node, children);
    }

    // Return Created Node
    return node;
}
