import attrOption from './.internal/options/attrOption';
import EventsManager from './EventsManager';
import addClass from '../fn/attributes/addClass';
import removeClass from '../fn/attributes/removeClass';
import select from '../fn/select/select';
import setUndefined from '../helpers/object/setUndefined';
import className from '../helpers/utils/className';
import sleep from '../methods/utils/sleep';

/*
 * Input Group functionalities
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const InputFloat = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: InputFloat.SELECTOR,

        // Options
        empty: InputFloat.EMPTY
    });

    // Set Options from Attr
    options = attrOption(options, el, ['empty'], options.selector);

    // Selectors
    const selectors = {

        // States
        vData: '.v--data',
        vFocus: '.v--focus'
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
InputFloat.prototype.init = function () {
    const that = this;

    // Elements
    let elInput = select('input', that.el);
    if (!elInput) {
        elInput = select('select', that.el);
    }

    // Classes
    const classFocus = className(that.selectors.vFocus);
    const classData = className(that.selectors.vData);

    // Input
    if (elInput) {

        // When using the back button of after the autofill from the navigator, the
        // value can be marked as empty. The following hack aims at checking the value
        // after a short amount of time
        if (elInput.value !== that.options.empty) {
            addClass(that.el, classData);
        } else {
            sleep(25).then(() => {
                if (elInput.value !== that.options.empty) {
                    addClass(that.el, classData);
                }
            });
        }

        // Listener - Focus
        that.events.on(elInput, 'focus', e => {
            e.preventDefault();
            addClass(that.el, classFocus);
        });

        // Listener - Blur
        that.events.on(elInput, 'blur', e => {
            e.preventDefault();
            removeClass(that.el, classFocus);
            if (elInput.value !== that.options.empty) {
                addClass(that.el, classData);
            } else {
                removeClass(that.el, classData);
            }
        });
    }
};

/**
 * Destroy
 */
InputFloat.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
InputFloat.SELECTOR = '.js-inputFloat';
InputFloat.EMPTY = '';

export default InputFloat;
