import attrOption from './.internal/options/attrOption';
import EventsManager from './EventsManager';
import ScrollVibe from './ScrollVibe';
import Tweener from './Tweener';
import select from '../fn/select/select';
import each from '../helpers/collection/each';
import parseBool from '../helpers/lang/parseBool';
import setUndefined from '../helpers/object/setUndefined';
import imageLoaded from '../methods/utils/imageLoaded';

/*
 * Reveal images in an elegant way.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const ImageReveal = function (el, options) {
    const that = this;

    // Options - Selector
    setUndefined(options, {
        selector: ImageReveal.SELECTOR,

        // Options
        autostart: ImageReveal.AUTOSTART,
        mode: ImageReveal.MODE,
        direction: ImageReveal.DIRECTION,
        scale: ImageReveal.SCALE,
        ease: ImageReveal.EASE,
        speed: ImageReveal.SPEED,
        delay: ImageReveal.DELAY,
        start: ImageReveal.START,
        debug: ImageReveal.DEBUG
    });

    // Set Options from Attr
    options = attrOption(options, el, ['autostart', 'mode', 'ease', 'speed', 'delay', 'scale', 'direction', 'start', 'trigger', 'debug'], options.selector);

    // Boolean values
    options.autostart = parseBool(options.autostart);
    options.debug = parseBool(options.debug);

    // Selectors
    const selectors = {

        // Elements
        wrapper: `${options.selector}__wrapper`,
        image: `${options.selector}__image`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();
    that.vibes = [];

    // Init
    if (that.options.autostart) {
        that.init();
    }
};

/**
 * Init
 */
ImageReveal.prototype.init = function () {
    const that = this;

    // Listener - Reveal
    that.events.once(that.el, 'handleReveal', () => {
        that.reveal();
    });

    // Mode = Load
    if (that.options.mode === 'load') {
        imageLoaded(that.el).then(() => {
            that.events.trigger(that.el, 'handleReveal');
        });

    // Mode = Scroll
    } else if (that.options.mode === 'scroll') {
        that.vibes.push(new ScrollVibe(that.el, {
            debug: that.options.debug,
            trigger: that.options.trigger ? select(that.options.trigger) : that.el,
            reverse: false,
            do: {
                start: that.options.start,
                event: 'handleReveal'
            }
        }));
    }
};

/**
 * Trigger 'onReveal'
 */
ImageReveal.prototype.onReveal = function () {
    const that = this;

    // Trigger event
    that.events.trigger(that.el, 'onReveal');
};

/**
 * Reveal
 */
ImageReveal.prototype.reveal = function () {
    const that = this;

    // Elements
    const elWrapper = select(that.selectors.wrapper, that.el);
    const elImage = select(that.selectors.image, elWrapper);

    // Fct - Init
    const init = () => {
        return new Promise(resolve => {
            resolve();
        });
    };

    // Fct - Done
    const done = () => {
        return new Promise(resolve => {
            that.onReveal();
            resolve();
        });
    };

    // Fct - Image -> Show
    const imageShow = () => {
        return new Promise(resolve => {

            const tween = new Tweener({ speed: that.options.speed, delay: that.options.delay, ease: that.options.ease })
                .onStart(() => {
                    Tweener.set([elImage, elWrapper], { opacity: 1 });
                });

            // Left
            if (that.options.direction === 'left') {
                tween
                    .add(elWrapper, {
                        x: { from: '100%', to: 0 }
                    })
                    .add(elImage, {
                        x: { from: '-100%', to: 0 },
                        scale: { from: that.options.scale, to: 1 }
                    });

            // Bottom
            } else if (that.options.direction === 'bottom') {
                tween
                    .add(elWrapper, {
                        y: { from: '-100%', to: 0 }
                    })
                    .add(elImage, {
                        y: { from: '100%', to: 0 },
                        scale: { from: that.options.scale, to: 1 }
                    });

            // Top
            } else if (that.options.direction === 'top') {
                tween
                    .add(elWrapper, {
                        y: { from: '100%', to: 0 }
                    })
                    .add(elImage, {
                        y: { from: '-100%', to: 0 },
                        scale: { from: that.options.scale, to: 1 }
                    },);

            // Right
            } else {
                tween
                    .add(elWrapper, {
                        x: { from: '-100%', to: 0 }
                    })
                    .add(elImage, {
                        x: { from: '100%', to: 0 },
                        scale: { from: that.options.scale, to: 1 }
                    });
            }

            tween.play(() => {
                resolve();
            });
        });
    };

    // Init & proceed
    init().then(() => {
        imageShow().then(() => {
            done();
        });
    });
};

/**
 * Destroy
 */
ImageReveal.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
    each(that.vibes, vibe => {
        vibe.destroy();
    });
};

/**
 * Constants
 */
ImageReveal.SELECTOR = '.js-imageReveal';
ImageReveal.AUTOSTART = true;
ImageReveal.MODE = 'load';
ImageReveal.DIRECTION = 'right';
ImageReveal.SPEED = 1500;
ImageReveal.EASE = 'power2.in';
ImageReveal.DELAY = 0;
ImageReveal.SCALE = 1;
ImageReveal.START = 'top 80%';
ImageReveal.DEBUG = false;

export default ImageReveal;
