"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Is an HTML formated string ?
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {string} str
 *
 * @returns {boolean}
 */
function default_1(str) {
    var docu = new DOMParser().parseFromString(str, 'text/html');
    return Array.from(docu.body.childNodes).some(function (node) { return node.nodeType === 1; });
}
exports.default = default_1;
