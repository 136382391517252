import select from '../select/select';

/*
 * Images can be 'cover', 'contain' or 'fill'. The classic measurement methods may
 * not work depending on the image. The function returns all the real size
 * information about an image
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 *
 * @returns {Object}
 */
export default function (node) {

    // Select matching node
    node = select(node);

    // Return the real rendered size of the image
    function getRenderedSize(contains, cWidth, cHeight, width, height, pos) {
        const oRatio = width / height;
        const cRatio = cWidth / cHeight;

        const size = {};

        if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
            size.width = cWidth;
            size.height = cWidth / oRatio;
        } else {
            size.width = cHeight * oRatio;
            size.height = cHeight;
        }

        size.left = (cWidth - size.width) * (pos / 100);
        size.right = size.width + size.left;

        size.top = (cHeight - size.height) * (pos / 100);
        size.bottom = size.top + size.height;

        return size;
    }

    // Image properties
    const objectPosition = window.getComputedStyle(node).getPropertyValue('object-position').split(' ')[0];
    const objectFit = window.getComputedStyle(node).getPropertyValue('object-fit').split(' ')[0];

    // Node provided
    return node ? getRenderedSize(objectFit === 'contain', node.width, node.height, node.naturalWidth, node.naturalHeight, parseInt(objectPosition, 10)) : null;
}
