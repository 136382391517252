import addClass from './addClass';
import removeClass from './removeClass';

/*
 * Remove 1 or more classes to 1 or more nodes.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} classNameToRemove
 * @param {string|Array} classNameToAdd
 * @param {Node} context
 */
export default function (nodes, classNameToRemove, classNameToAdd, context) {

    // Remove class(es)
    removeClass(nodes, classNameToRemove, context);

    // Add class(es)
    addClass(nodes, classNameToAdd, context);
}
