import attrOption from './.internal/options/attrOption';
import EventsManager from './EventsManager';
import addClass from '../fn/attributes/addClass';
import removeClass from '../fn/attributes/removeClass';
import setHtml from '../fn/manipulation/setHtml';
import select from '../fn/select/select';
import setUndefined from '../helpers/object/setUndefined';
import className from '../helpers/utils/className';

/*
 * Allows to interact with a Carousel
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node} el
 * @param {Object} options
 */
const CarouselNavigation = function (el, options) {
    const that = this;

    options = options || {};

    // Options - Selector
    setUndefined(options, {
        selector: CarouselNavigation.SELECTOR
    });

    // Set Options from Attr
    options = attrOption(options, el, ['rel'], options.selector);

    // Selectors
    const selectors = {

        // Statuses
        vActive: '.v--active',

        // Elements
        previous: `${options.selector}__previous`,
        next: `${options.selector}__next`,
        numerationCurrent: `${options.selector}__numeration-current`,
        numerationTotal: `${options.selector}__numeration-total`
    };

    // Element, Options, Selectors & Events
    that.el = el;
    that.options = options;
    that.selectors = selectors;
    that.events = new EventsManager();

    // Init
    that.init();
};

/**
 * Init
 */
CarouselNavigation.prototype.init = function () {
    const that = this;

    // Elements
    const elCarousel = that.options.rel ? select(that.options.rel) : that.el;
    const elPrevious = select(that.selectors.previous, that.el);
    const elNext = select(that.selectors.next, that.el);
    const elNumerationCurrent = select(that.selectors.numerationCurrent, that.el);
    const elNumerationTotal = select(that.selectors.numerationTotal, that.el);

    // Classes
    const classActive = className(that.selectors.vActive);

    // Listener - On Select
    that.events.on(elCarousel, 'onSelect', e => {

        // Previous
        if (elPrevious) {
            if (e.detail.previous) {
                addClass(elPrevious, classActive);
            } else {
                removeClass(elPrevious, classActive);
            }
        }

        // Next
        if (elNext) {
            if (e.detail.next) {
                addClass(elNext, classActive);
            } else {
                removeClass(elNext, classActive);
            }
        }

        // Numeration
        if (elNumerationCurrent) {
            setHtml(elNumerationCurrent, `${e.detail.index + 1}`);
        }
        if (elNumerationTotal) {
            setHtml(elNumerationTotal, `${e.detail.slides}`);
        }
    });

    // Listener - Previous
    if (elPrevious) {
        that.events.on(elPrevious, 'click', e => {
            e.preventDefault();
            that.events.trigger(elCarousel, 'handlePrevious');
        });
    }

    // Listener - Next
    if (elNext) {
        that.events.on(elNext, 'click', e => {
            e.preventDefault();
            that.events.trigger(elCarousel, 'handleNext');
        });
    }
};

/**
 * Destroy
 */
CarouselNavigation.prototype.destroy = function () {
    const that = this;

    that.events.destroy();
};

/**
 * Constants
 */
CarouselNavigation.SELECTOR = '.js-carouselNavigation';

export default CarouselNavigation;
