import matches from './matches';
import select from './select';

/*
 * Select matching parent node from the DOM and return it.
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|NodeList|string} node
 * @param {string} selector
 *
 * @returns {Node|null}
 */
export default function (node, selector) {

    // Select matching node
    node = select(node);

    // Node provided
    if (node) {

        // Get the closest matching parent node
        let parentNode = node.parentNode;

        while (parentNode && parentNode !== document) {
            if (matches(parentNode, selector)) {
                return parentNode;
            }
            parentNode = parentNode.parentNode;
        }
    }

    return null;
}
