import lazySizes from 'lazysizes';

/*
 * Lazy load images (or something else) on a webpage
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 */

// Settings for LazySizes
lazySizes.cfg.loadMode = 2;
lazySizes.cfg.lazyClass = 'js-lazy';
lazySizes.cfg.preloadClass = 'js-lazy--preload';
lazySizes.cfg.loadingClass = 'js-lazy--loading';
lazySizes.cfg.loadedClass = 'js-lazy--loaded';
lazySizes.cfg.init = false;
lazySizes.cfg.throttleDelay = 15;

// Start loaded lazy elements instantly !
lazySizes.init();
