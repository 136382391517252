import select from '../select/select';

/*
 * Remove all children from a node
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 */
export default function (node) {

    // Select matching node
    node = select(node);

    // DOM node exists, Append Children
    if (node) {
        while (node.firstChild) {
            node.removeChild(node.firstChild);
        }
    }
}
