import 'arrive';
import Reef from '../Reef';
import attr from '../fn/attributes/attr';
import selectAll from '../fn/select/selectAll';
import each from '../helpers/collection/each';
import merge from '../helpers/object/merge';
import parseJson from '../helpers/string/parseJson';
import dataName from '../helpers/utils/dataName';
import InputFloat from '../utilities/InputFloat';
import Plugins from '../utilities/Plugins';

/*
 * Plugin loader for InputFloat
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 */

(reef => {

    /**
     * Main
     *
     * @param {Object} options
     * @param {Node} context
     */
    reef.plugins.inputFloat = function (options, context) {

        // Options
        options = merge(reef.plugins.inputFloat.defaults, options);

        // Init
        const init = el => {
            if (Plugins.canRegister(el, options.selector)) {
                Plugins.register(el, options.selector, new InputFloat(el, merge(options, parseJson(attr(el, dataName(options.selector))))));
            }
        };

        // Initial Init
        each(selectAll(options.selector, context), el => {
            init(el);
        });

        // Arriving Nodes
        document.arrive(options.selector, function () {
            init(this);
        });
    };

    /**
     * Defaults
     */
    reef.plugins.inputFloat.defaults = {
        selector: InputFloat.SELECTOR
    };

})(Reef);
