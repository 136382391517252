import select from '../select/select';

/*
 * Prepend Node to a Node
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 *
 * @param {Node|string} node
 * @param {Node} child
 */
export default function (node, child) {

    // Select matching node
    node = select(node);

    // DOM node exists, prepend Child
    if (node) {
        node.prepend(child);
    }
}
